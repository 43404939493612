import React from "react";
import LINE from "../../Assets/SVG/line.svg";
import picture from "../../Assets/Images/picture.jpg";
import one from "../../Assets/SVG/one.svg";
import two from "../../Assets/SVG/two.svg";
import three from "../../Assets/SVG/three.svg";

const Approach = () => {
  return (
    <div className="pt-12 md:pt-24 font-Pop">
        <div className="flex flex-col justify-center items-center gap-1 md:gap-3">
            <div className="flex items-center justify-center gap-2">
                <img src={LINE} alt="line-svg" />
                <h4 className="tracking-wider text-base text-black">Our Way</h4>
            </div>
            <h1 className="font-medium tracking-wider text-xl md:text-5xl text-[#26313C]">  Approach </h1>
        </div>
        <div className="flex md:grid grid-cols-2 gap-2 w-full h-full  md:mt-12 lg:mt-16 px-2 md:px-0">
            <div className="flex flex-col justify-around h-full gap-5 md:gap-0 text-xs tracking-widest lg:text-base text-justify md:text-start mt-4 md:mt-0 pl-2 md:pl-10 leading-5" style={{color:'darkslategray'}}>
                <p> <span className="font-bold font-Manrope uppercase mr-1">SDLC Expertise:</span>We ensure transparency and predictability with a structured SDLC process.</p>
                <p> <span className="font-bold font-Manrope uppercase mr-1">Global Delivery Model:</span>Blended onshore-offshore model ensures cost-effective, quality solutions, seamless communication.</p>
                <p> <span className="font-bold font-Manrope uppercase mr-1 text-nowrap">  Data Integrity Champions:</span> Proven processes ensure data integrity with clean, accurate information.</p>
            </div>
            <div className="md:relative flex">
                <div className="w-full hidden md:block">
                    <img src={picture} alt="ourMission" className="h-[70vh] md:w-[91%] lg:w-[93%] float-right"/>
                </div>
                <div className="md:absolute left-0 h-full w-8 md:w-auto">
                    <div className="flex flex-col justify-around h-full gap-5 md:gap-0">
                        <img src={one} alt="one" className="w-8 md:w-16 xl:w-24 h-auto" />
                        <img src={two} alt="two" className="w-8 md:w-16 xl:w-24 h-auto"/>
                        <img src={three} alt="three" className="w-8 md:w-16 xl:w-24 h-auto"/>
                    </div>                  
                </div>
            </div>     
        </div>
    </div>
  );
}
export default Approach;
