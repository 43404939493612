const WorkProcess = () => {
  return (
    <section className="bg-[#26313C] font-Pop py-7 px-1 xl:px-0">
      <div className="container mx-auto 2xl:py-12 xl:py-10 lg:py-8">
        <h2 className="text-center 2xl:text-6xl xl:text-5xl lg:text-4xl md:text-3xl text-2xl text-white font-medium tracking-wider md:mb-16 mb-6"> Our Work Process </h2>
        <div className="relative flex flex-col lg:flex-row items-center justify-center">
          <div className="absolute inset-0 mx-auto h-0.5 bg-[#ffffff47] opacity-0.5 xl:max-w-[80%] md:max-w-[80%] xl:translate-y-12 md:translate-y-8 lg:block hidden"></div>
            <div className="relative flex flex-col lg:flex-row  justify-between w-full xl:max-w-[100%] md:max-w-[100%] px-5 md:px-2 gap-5 lg:gap-0">
              {PROCESS.map((step, index) => (
                <div key={index} className="relative flex flex-col items-center text-center md:gap-4" >
                  <div className="xl:w-24 xl:h-24 md:h-16 md:w-16 h-10 w-10 bg-[#26313C] rounded-full border-2 border-[#ffffff47] flex items-center justify-center transition-all duration-300 hover:bg-gradient-to-t hover:from-[#25333F] hover:to-[#1266E3]">
                    <span className="text-white text-lg font-bold"> 0{index + 1} </span>
                  </div>
                  <h3 className="text-white text-lg font-base uppercase mt-2 2xl:text-2xl lg:text-lg tracking-wider text-nowrap"> {step.title} </h3>
                  <p className="text-gray-300 md:mx-28 lg:max-w-xs lg:mx-0 mb-5 tracking-widest text-center lg:text-justify px-4" style={{ fontSize: "10px" }}> {step.description} </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  };
export default WorkProcess;

const PROCESS = [
  {
    title: "Meet Consultant",
    description:
      "Engaging clients, assessing needs, evaluating feasibility, documenting requirements, user stories, use cases, and specifications.",
  },
  {
    title: "Expert Advice",
    description:
      "Designing system architecture, planning project timelines, allocating resources, and developing prototypes for feedback.",
  },
  {
    title: "Development",
    description: "Agile development, coding, testing, and deploying software with continuous improvement and user feedback.",
  },
  {
    title: "Testing",
    description: "Conducting thorough testing and quality assurance to ensure high standards, identify bugs, and ensure functionality.",
  },
  {
    title: "Delivery",
    description:
      "Planning deployment and providing go-live support to address issues and ensure smooth execution.",
  },
];

