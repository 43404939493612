import React from 'react'

const WhyWe = () => {
    const features = [
        {
          title: "Experienced Team:",
          description:
            "Our consultants possess a wealth of knowledge and have a proven track record of success in delivering complex software projects.",
        },
        {
          title: "Focus on Client Satisfaction:",
          description:
            "We prioritize building strong relationships with our clients and ensuring they are completely satisfied with the final product.",
        },
        {
          title: "Cost-Effectiveness:",
          description:
            "We understand your budgetary needs and create cost-effective solutions that deliver exceptional value.",
        },
      ];
    
      return (
        <div className="bg-[#26313C] text-white  py-7 lg:py-10 w-full mt-12 md:mt-24 lg:min-h-[80vh] h-auto ">
          <h2 className="md:text-5xl text-2xl font-medium mb-6 md:mb-12 text-center font-Pop tracking-wider">Why Immanuel Tech?</h2>
          <div className="xl:ml-16 lg:block hidden px-2 lg:px-10">
              {features.map((feature, index) => (
                  <div key={index} className={`font-Manrope overflow-hidden xl:mr-20 mb-7`} style={{ marginLeft: `${index * 270}px` }} >
                      <div className='border-l-4 pl-2'>
                          <h3 className="text-lg font-semibold mb-2 text-[#ffffffc7] uppercase tracking-wider ">{feature.title}</h3>
                          <p className="text-[#ffffffc7] text-wrap text-sm md:text-sm tracking-widest font-Pop ">{feature.description}</p>
                      </div>
                  </div>
              ))}
          </div>
          <div className=" block lg:hidden">
          {features.map((feature, index) => (
                  <div key={index} className="font-Manrope overflow-hidden xl:mr-20 mt-10 px-4"  >
                      <div className=' border-l-4 pl-2'>
                          <h3 className="text-sm mb-2 text-white uppercase tracking-widest ">{feature.title}</h3>
                          <p className="text-[#ffffffc7] text-wrap text-xs  tracking-widest font-Pop text-justify font-light ">{feature.description}</p>
                      </div>
                  </div>
              ))}
          </div>
        </div>
      );  
    }
export default WhyWe
