import React from 'react';
import { LINE } from '../../Assets/SVG';
import IMG_1 from "../../Assets/Images/aboutus-1.jpg";
import IMG_2 from "../../Assets/Images/aboutus-2.jpg";

const AboutUs = () => {
  return (
    <section id="aboutUs" className="pt-12 md:pt-24 font-Pop">
      <div className="flex flex-col justify-center items-center gap-1 md:gap-2.5">
        <div className="flex items-center justify-center gap-2">
          <img src={LINE} alt="line-svg" />
          <h4 className="tracking-wider text-base text-black">Our Introduction</h4>
        </div>
        <h1 className="font-medium tracking-wider text-xl md:text-5xl text-[#26313C]">About Us</h1>
      </div>
      <div className="mt-6 md:mt-16">
        <div className='grid grid-cols-1 md:grid-cols-4'>
          <div className="col-span-4 lg:col-span-3 px-5 md:px-0 py-7 md:pl-16 md:pr-20 bg-[#D9D9D9]">
            <p className="tracking-widest leading-8 text-justify text-sm font-light" style={{color:'darkslategray'}}>ImmanuelTech is a team of passionate and experienced IT consultants dedicated to providing exceptional software solutions for our clients. We combine onshore and offshore expertise to deliver cost-effective projects while adhering to the rigorous standards of the Software Development Life Cycle (SDLC).</p>
          </div>
          <div className="bg-[#8891A6] py-10 hidden lg:flex">
            <div className="pl-10 pr-14 h-72">
              <img src={IMG_1} className="h-full w-full object-cover" alt='image1' />
            </div>
          </div>
        </div>
        <div className="h-32 md:h-64 overflow-hidden">
          <img src={IMG_2} className="w-full h-full object-cover" alt='image2'/>
        </div>
      </div>
    </section>
  )
}
export default AboutUs;