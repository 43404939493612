import React, { useState, useEffect } from 'react';
import IMG_1 from "../../Assets/Images/carousel-1.jpg";
import IMG_2 from "../../Assets/Images/carousel-2.jpg";
import IMG_3 from "../../Assets/Images/carousel-3.jpg";
import IMG_4 from "../../Assets/Images/carousel-4.jpg";

const Hero = () => {
  const slides = [
    { image: IMG_1, text: "Struggling to turn your ideas into impactful software solutions." },
    { image: IMG_2, text: "Are inefficiences in your SDLC delaying project delivery?" },
    { image: IMG_3, text: "Need expert guidance to navigate complex tech challenges?" },
    { image: IMG_4, text: "Facing barriers in scaling and deploying applications seamlessly?" },
  ];

  return (
    <section className="px-1.5 py-1 md:px-4 md:py-2.5">
      <Carousel slides={slides} />
    </section>
  )
}

export default Hero;

const Carousel = ({ slides }) => {

  const [current, setCurrent] = useState(0);

  const nextSlide = () => {
    setCurrent(prev => (prev + 1) % slides.length);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="overflow-hidden bg-white">
      <div className="relative flex transition-opacity duration-0 ease-in" style={{ transform: `translateX(-${current * 100}%)` }}>
        {slides?.map((slide, i) => (
          <div key={i} className="w-full flex flex-shrink-0 h-[60vh] md:h-full ">
            <img alt={`Slide ${i + 1}`} src={slide.image} className="w-full h-full flex object-cover" />
          </div>
        ))}
      </div>
      <div className="absolute inset-0 grid grid-cols-2 font-Manrope">
        <div className="flex items-center place-content-center">
          <h1 className="text-6xl md:text-[14rem] tracking-wider font-bold text-[#8891A6] opacity-80">{`0${current + 1}`}</h1>
        </div>        
        <div className="flex flex-col justify-center text-justify md:text-end text-white -mt-40 md:-mt-20 pr-5 md:pr-20 ">
          <h1 className="text-xl md:text-4xl xl:text-6xl font-black tracking-wider leading-relaxed ">{slides[current].text}</h1>
        </div>
      </div>
    </div>
  );
};