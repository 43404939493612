import React, { useState } from 'react';
import { AnimatePresence, motion } from "framer-motion";
import { LINE, DATA_ANT, DATA_EXT, DATA_MNG, WEBDEV, SUPPORT } from '../../Assets/SVG';

const Services = () => {

  const [selected, setSelected] = useState(0);

  return (
    <section id="services" className="px-5 md:px-16 pt-12 md:pt-24 font-Pop pb-6 md:pb-14">
      <div className="flex flex-col justify-center items-center gap-1 md:gap-2.5">
        <div className="flex items-center justify-center gap-2">
          <img src={LINE} alt="line-svg" />
          <h4 className="tracking-wider text-xs md:text-base">What we Provide</h4>
        </div>
        <h1 className="font-medium tracking-wider text-xl md:text-5xl text-[#26313C]">Our Services</h1>
      </div>
      <p className="tracking-widest text-justify md:text-center leading-7 mt-5 text-sm md:text-base font-light" style={{color:'darkslategray'}}> "Our Software Development service transforms your unique ideas into robust, user-friendly applications tailored to meet your business needs. With a focus on innovation and scalability, we ensure seamless integration and exceptional performance. Let us help you drive growth and stay ahead in today's competitive digital landscape." </p>
      <div className="pt-6 md:pt-16">
        <Tabs selected={selected} setSelected={setSelected} />
        <AnimatePresence mode="wait">
          {FEATURES.map((tab, index) => {
            return selected === index ? (
              <motion.div key={index} initial={{ opacity: 0, y: 10 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: 10 }}>
                <tab.Feature />
              </motion.div>
            ) : undefined;
          })}
        </AnimatePresence>
      </div>
    </section>
  )
}
export default Services;

const Tabs = ({ selected, setSelected }) => {
  return (
    <div className="flex overflow-x-scroll no-scrollbar">
      {FEATURES.map((tab, index) => {
        return (
          <Tab key={index} setSelected={setSelected} selected={selected === index} Icon={tab.Icon} tabNum={index} />
        );
      })}
    </div>
  );
};

const Tab = ({ selected, Icon, setSelected, tabNum }) => {
  return (
    <div className="relative w-full">
      <button onClick={() => setSelected(tabNum)} className="relative z-0 flex w-full flex-row items-center justify-center gap-4 p-1 md:p-6 transition-colors hover:bg-slate-100 md:flex-col">     
        <span className={`p-2 md:p-3 transition-all duration-300 ${selected ? "scale-100 opacity-70" : "scale-90 opacity-30"}`}>
          <img alt="images" className='w-10 md:w-auto h-10 md:h-auto' src={Icon} />
        </span>
      </button>
      {selected && (
        <motion.span layoutId="tabs-features-underline" className="absolute bottom-0 left-0 right-0 z-10 h-0.5 md:h-1 bg-[#1266E3]"/>
      )}
    </div>
  );
};

const ExampleFeature = ({ title, description }) => (
  <div className="w-full px-0 py-8 md:px-2 xl:px-8">
    <div className="relative h-auto md:h-80 w-full rounded-xl bg-white shadow-2xl px-5 py-2.5 overflow-auto ">
      <p className="font-Pop font-medium text-3xl md:text-4xl tracking-widest">{title}</p>
      <p className="mt-5 font-Pop font-light text-sm md:text-base text-justify tracking-widest leading-6" style={{color:'darkslategray'}}>{description}</p>
    </div>
  </div>
);

const FEATURES = [
  {
    Icon: WEBDEV,
    Feature: () => <ExampleFeature Icon={WEBDEV} title="Software Development" description="We excel in delivering high-quality software solutions through a robust Software Development Life Cycle (SDLC). Our unique approach combines the expertise of onshore and offshore teams, allowing us to optimize project costs while maintaining the highest standards. By strategically leveraging a global talent pool, we provide cost-effective solutions without compromising on quality or communication. This collaborative model ensures efficient development, timely delivery, and successful project outcomes that exceed your expectations." />,
  },
  {
    Icon: DATA_MNG,
    Feature: () => <ExampleFeature Icon={DATA_MNG} title="Data Management" description="ImmanuelTech provides comprehensive data management consulting services to help organizations unlock the true value of their data. Our experts assist in developing and implementing robust data governance strategies, improving data quality, enhancing data security, and optimizing data integration processes. We leverage industry best practices and cutting-edge technologies to ensure data accuracy, consistency, and accessibility, empowering businesses to make informed decisions, gain a competitive advantage, and drive innovation." />,
  },
  {
    Icon: DATA_ANT,
    Feature: () => <ExampleFeature Icon={DATA_ANT} title="Data Analytics" description="ImmanuelTech provides a wide range of data analytics services to help businesses gain valuable insights from their data. Our team of data analysts utilizes advanced statistical and machine learning techniques to uncover hidden patterns, identify trends, and predict future outcomes. We can help you with data visualization, predictive modeling, customer segmentation, market analysis, and more. By leveraging our data analytics expertise, you can make data-driven decisions, improve operational efficiency, enhance customer experiences, and achieve your business goals" />,
  },
  {
    Icon: SUPPORT,
    Feature: () => <ExampleFeature Icon={SUPPORT} title="Support & Maintanence" description="ImmanuelTech provides ongoing maintenance and support for your software and data solutions. Our comprehensive support services include bug fixes, software updates, data backups and recovery, performance tuning, and
proactive monitoring. We ensure the smooth and uninterrupted operation of your systems, provide timely assistance for any issues that arise, and continuously improve the performance and reliability of your software and data infrastructure. With our dedicated support team, you can focus on your core business while we take care of the technical aspects, ensuring your systems are always running optimally." />,
  },
  {
    Icon: DATA_EXT,
    Feature: () => <ExampleFeature Icon={DATA_EXT} title="Data Extraction" description="ImmanuelTech provides expert data extraction services to help businesses efficiently and accurately retrieve data from various sources. Our team utilizes a combination of manual and automated techniques, including web scraping, API integration, and database querying, to extract data from websites, databases, documents, and other sources. We ensure data accuracy, completeness, and compliance with all relevant regulations. 
    Our data extraction services enable businesses to gather valuable insights, improve data quality, and streamline their data management processes, ultimately leading to better decision-making and increased efficiency. Our advanced data extraction solutions help you unlock the power of de-identified healthcare data. By precisely extracting valuable insights from patient records and clinical notes, we ensure data integrity and privacy. Our services, including data cleaning, normalization, enrichment, and advanced analytics, empower you to make informed decisions, improve patient care, accelerate medical research, and optimize operational efficiency." />,
  }
];